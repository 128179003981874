
import VuePhoneNumberInput from 'vue-phone-number-input'
import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
    components: {
        VuePhoneNumberInput,
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: Object,
        },
        title: {
            type: String,
            default: String,
        },
        isElementsTogether: {
            type: Boolean,
            default: false,
        },
        translations: {
            type: Object,
            default: Object,
        },
        hasBorder: {
            type: Boolean,
            default: false,
        },
        hasImage: {
            type: Boolean,
            default: false,
        },
        titleColor: {
            type: String,
            default: '',
        },
        isDark: {
            type: Boolean,
            default: false,
        },
        textColor: {
            type: String,
            default: '#C39C67',
        },
        customClass: {
            type: String,
            default: '',
        },
        showOnlyCode: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            phoneModel: null,
            isFieldFocus: null,
        }
    },
    computed: {
        inputValue: {
            get () {
                return this.value
            },
            set (value) {
                this.inputValue = value
            },
        },
        labelClasses () {
            return {
                'text-gray-8': this.isFieldFocus,
                'font-[CormorantGaramond] text-[18px] tablet:text-[24px]': this.hasBorder,
                'text-error': this.value.$error,
                [`text-[${this.titleColor}]`]: this.titleColor,
            }
        },
        inputClasses () {
            return {
                'vue-phone-number-input together': this.isElementsTogether,
                hasBorder: this.hasBorder,
                error: this.value.$error,
                '!border-error': this.value.$error,
                [`!border-[${this.textColor}]`]: this.isFieldFocus && !this.value.$error,
                [`!text-[${this.textColor}]`]: this.isFieldFocus && !this.value.$error,
                hasImage: this.hasImage,
                [`${this.customClass}`]: this.customClass,
            }
        },
    },
    watch: {
        'value.$model' () {
            if (this.value.$model === null) { this.phoneModel = null }
            // if (this.phoneModel === null) {
            //     this.phoneModel = this.value.$model?.slice(4)
            // }
        },
    },
    created () {
        this.phoneModel = this.value.$model?.slice(3)
    },
    mounted () {
        const input = document.getElementsByClassName('input-tel__input')[0]
        input.dir = this.$dir()
    },
    methods: {
        setErrorMessage (model) {
            if (model.$error) {
                switch (true) {
                case !model?.required:
                    return 'The phone number is wrong'
                }
            }
        },
        setPhoneNumber (values) {
            if (values?.phoneNumber) {
                const phoneNumberData = parsePhoneNumber(
                    values.phoneNumber, { regionCode: values.countryCode },
                )
                if (values.isValid && phoneNumberData.valid) {
                    this.inputValue.$model = phoneNumberData.number.e164
                } else {
                    this.inputValue.$model = ''
                }
            }
        },
    },
}
